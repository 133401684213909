import httpClient from "../httpClient"

import { formatData } from "../request"
import { encodeRequestParameters } from "../request"

import { accessFormat } from "../formats"

/**
 * Returns a list of residences
 */
export function getAccess(data) {
    data = formatData(accessFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/acces", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * call OpenDoor to open an access
 */
export function openAccess(id) {
    return httpClient
        .post("/access/" + id + "/open", {})
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}
